footer .weitere-infos {
	background: $gray-footer url(../../images/footer.gif) repeat-x bottom;
	min-height:170px;
	border-top:1px solid $white;
	padding:$standardabstand 15px 180px 15px;
}
footer .weitere-infos h3{
	margin:0 0 20px 0;
}

footer .weitere-infos a.telefonnummer,
footer .weitere-infos a.download {
	color:$black;
	display:inline-block;
	font-weight:normal;
	font-family: $font-bold-montserrat;
	margin:10px 0 0 0;
	min-height:29px;
	padding:5px 0 0 $standardabstand;
}
footer .weitere-infos a.download {
	background: transparent url(../../images/icons/pdf.png) no-repeat left;
}
footer .weitere-infos a.telefonnummer{
	background: transparent url(../../images/icons/tel.png) no-repeat left;
}
footer .weitere-infos a.telefonnummer:hover{
	text-decoration:none;
}
footer .copyright {
	background: $black;
	color:$white;
	padding:5px 15px;
}
footer .copyright h6 {
	font-family: $font-regular-montserrat;
	font-weight: normal;
	padding:3px 0 0 0;
}
footer .copyright ul {
	list-style: none;
	text-align: right;
}
footer .copyright ul li {
	display: inline-block;
	color: $white;
}
footer .copyright ul li a {
	color: $white;
	padding: 0 10px;
	border-right: 1px $white solid;
}
footer .copyright ul li:first-child a {
	padding-left: 0;
}
footer .copyright ul li:last-child a {
	border-right: 0;
	padding-right: 0;
}

footer .weitere-infos .inhalt {
	padding: 5px;
	font-size: 14px;
}

@media (max-width: 768px) { 
	.weitere-infos .footerlinks .inhalt p,
	.weitere-infos .footerrechts .inhalt p {
		font-size:12px;
	}	
}
 
@media (max-width: 600px) {
	.weitere-infos .footerrechts {
		margin-top: $standardabstand;
	}
	footer .copyright ul {
		text-align: left;
	}
}


	
	
	
