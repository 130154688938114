.bereich-inhalt {
	padding: 0 15px;
}
.bereich-inhalt ul {
	margin: 10px 0 10px 15px;
}
.bereich-inhalt .inhalt-text ul li {
	margin: 0 0 15px 0;
}
.wrapper > .row {
	margin-left: -15px;
	margin-right: -15px;
}
.wrapper > .row > .columns {
	padding: 0 15px;
}
.ueberschrift h1, .ueberschrift h2 {
	font-family: $font-bold-roboto;
	font-size: 40px;
	margin-bottom: 10px;
	text-align: center;
}
main .bereich-inhalt #c35 > h3{
	margin-bottom:15px;
}
main .bereich-inhalt #c35{
	margin-bottom:-50px;
}

.ueberschrift .inhalt {
	font-family: $font-regular-roboto;
	margin-bottom: 23px;
	text-align: center;
}
.ueberschrift .inhalt,
.ueberschrift .inhalt p {
	font-size: 23px;
}
.inhalt-bilder .inhalt ul {
	list-style: none;
	margin: 0;
}
/* ckeditor Stil */
blockquote{
    padding: 0;
    border-left: none;
    color : inherit; 
}

blockquote p {
	color: inherit;
	font-size: inherit;
}

.zitat {
	margin: 20px auto;
	width:80%;
	font-size: 150%;
}

.zitatfarbig {
	margin: 20px auto;
	width:80%;
	font-family: $font-italic-montserrat;
	font-size: 150%;
	color: #B2C937;
	font-weight:bold;
}
.fazitklein {
	margin: 20px 0;
	padding: 20px 0 0 0;
	font-size: 110%;
	font-family: $font-bold-montserrat;
	position: relative; 
}
.fazit:before,
.fazitklein:before {
	border-top: 2px solid #000;
	content:" ";
	display: inline-block;
	left:0;
	position: absolute;
	top: 0;
	width: 50px;
}
.fazit {
	margin: 20px 20% 20px 0;
	width:80%;
	padding: 20px 0 0 0;
	font-size: 110%;
	font-weight:normal;
	position: relative; 
}

.zusammenfassung {
	border-left: 5px solid $blau;
	margin:20px 0;
	padding-left:20px;
	position: relative; 
}

.schrift-20{font-size:20px}
.schrift-22{font-size:22px}
.schrift-24{font-size:24px}
.schrift-26{font-size:26px}
.schrift-28{font-size:28px}
.schrift-30{font-size:30px}
.schrift-36{font-size:36px}
.schrift-40{font-size:40px}

/* interner Anker */
.marke{
	display:block;
	height:63px; /* Höhe Kopfbereich */
	margin-top:-63px; /* negativer Wert Höhe Kopfbereich */
	visibility:hidden;
}

.top {
	text-decoration: underline;
	cursor: pointer;
} 

main .inhalt-text ul li{
	margin: 0 0 0 15px;
}
.spalte-inhalt .inhalt-text .inhalt p {
	line-height: 1.6;
}
/* Haken Icon - Anpassung an td-Zelle unterbinden */
main .inhalt-text td img {
	max-width:none;
}

/* _text.scss foundation  */
input::placeholder, textarea::placeholder {
    color: $black;
}
span.form-text {
	display: block;
	font-family: $font-regular-montserrat;
	padding: 3px 0 0 0;
}
.unterstrichen {
	text-decoration: underline;
}
.hyphens {
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens:auto;
}
.tooltip-hilfe {
	font-weight: bold; 
	font-size: 20px; 
	color: $marine-blau; 
	cursor: help;
	float: right;
	border-bottom: 0;
}
.datumsfeld {
	width: 200px;
}
@media screen and (max-width:640px)
{
	.multiselect-label {
		position: relative;
		height: 75px;
	}
	.multiselect-label .chosen-container {
		position: absolute;
		left: 0;
		top: 28px;
		width: 100%;
	}
}