header	{
	/*min-height: 208px;*/
	/* max-height: 208px; */
	min-height: 0;
	//max-height: 0; 
	/*overflow: hidden;*/
}
.welcome header	{
	background: transparent url(/inhalte/kopf/startseite.gif) no-repeat center top;
	background-size: cover;
	min-height: 375px;
	max-height: 375px;	
}
.row .search-form-wrapper {
	margin-top: 35px;	
}
.search-form-wrapper h1 {
	color: #2362AF;
}
.search-form-wrapper h1 strong {
	font-family: $font-regular-roboto;
	font-weight: normal;
}
.search-form  {
	margin: 0 -5px;	
}
.search-form .columns {
	padding: 0 5px;	
}
#filtersuche {
	max-width: 75rem;
	margin: 60px auto 60px auto;
	padding: 60px 15px 0 15px;
}
.welcome #filtersuche {
	padding-top: 0;
}
.search-form button {
	background: #b2c937 url(../../images/icons/search.png) no-repeat center center;
	height: 45px;
	width: 40px;
}
.search-form input[type=text] {
	height: 45px;	
}
.search-form input[type=text]:focus {
	box-shadow: none;	
}

@media screen and (min-width: 800px) and (max-width: 900px){
	#anmelden_index header	{
		min-height: 100px;			
		max-height: 100px;
	}	
}


@media screen and (max-width: 600px)
{
	.search-form .columns {	
		padding-bottom: 1rem;
	}
	.search-form .input-group {
		margin-bottom: 0;
	}
	
	.search-form button {
		width: 100%;
	}

	.welcome .row .search-form-wrapper {
		margin-top: 0;
	}
	.detailsuche, .detailsuche:hover, .detailsuche:focus {
		background-color: #e6e6e6;
		width: 100%;
		position:relative;
		margin-top:-10px;
	}
}
@media screen and (max-width: 480px)
{
	.welcome header	{
		min-height: 415px;			
		max-height: 415px;
	}	
}

@media screen and (max-width: 360px) {
	#filtersuche {
		margin-top: 30px;
	}
}

.welcome #filtersuche {
	margin-top: 0;
}