.rootline {
	padding: 0 15px;
	margin: 0 0 45px 0;
}
.rootline > * {
	list-style: none;
	padding: 0;
	margin: 0 auto;
	max-width: $grid-row-width;	
}
.rootline > * > li {	
	display: inline-block;
}
.rootline > * > li > a {
	border-right: 1px $black solid;
	padding: 0 10px;
}
.rootline > * > li:first-child > a {
	padding-left: 0;
}
.rootline > * > li:last-child > a {
	color: $black;
	border-right: 0;
	text-decoration: underline;
}
@media screen and (max-width: 900px)
{
	.rootline {
		margin: 30px 0 30px 0;
	}
}