.no-js [data-responsive-toggle],
.no-js .submenu {
	display: none;
}
.seiten-logo {
	display: block;
	height: 25px;
	text-indent: -9999px;
	text-decoration: none;
	background: url(../../images/logo_klein.gif) no-repeat left top;
	background-size: 294px auto;
	width: 294px;	
}
nav.haupt-navi {
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}
body > header.abstand {
	padding: 85px 0 0 0;
}
.sticky-container {
	background:transparent;
}
.welcome .sticky-container, #anmelden_index .sticky-container {
	/* background: #fff; 
	 transparent */
}
.top-bar {
	width: 100%;
	z-index: 100;
	background: #fff; 
}
.top-bar .wrapper {
	margin: 0 auto;
	max-width: 75rem;
}
.top-bar-right > .dropdown {
	margin: 10px 0 0 0;
}
.top-bar-right ul {
	list-style: none;
}
.top-bar .dropdown {
	position: relative;
	margin-top: 10px;
}
.top-bar .is-dropdown-submenu-parent {
	position: static;	
}
.top-bar .dropdown.menu > li.opens-left > .is-dropdown-submenu {
	right: auto;	
}
.top-bar .dropdown li a {
	color: $black;
	font-family: $font-regular-montserrat;
}
.top-bar .dropdown > li > a {
	padding: 10px 20px 10px 10px;	
}
.dropdown.menu > li.is-dropdown-submenu-parent > a {
	padding-right:20px;
}
.top-bar .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
	border-color: $black transparent transparent;
}
.top-bar .submenu li a {
	display: block;
	padding: 3px 20px;
}
.top-bar-title {
	padding-left: 0;
	padding-right: 0;
}
.top-bar , .is-stuck, .top-bar ul.is-dropdown-submenu {
	-webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.35);
	-moz-box-shadow:    0px 3px 3px 0px rgba(0, 0, 0, 0.35);
	box-shadow:         0px 3px 3px 0px rgba(0, 0, 0, 0.35);	
}
.top-bar ul.is-dropdown-submenu {
	background: $black;
	border: 0;
	margin: 1px 0 0 0;
}

.bewerberhotlinetop {
	color:$black;
	display:inline-block;
	font-weight:normal;
	font-family: $font-bold-montserrat;
	min-height:29px;
	padding:5px 0 0 30px;
	width:180px;
}

.top-bar .bewerberhotlinetop,
.welcome .top-bar.is-stuck .bewerberhotlinetop {
	margin:0 0 0 20px;
}

.welcome .top-bar .bewerberhotlinetop {
	margin:10px 0 0 20px;
}	


.bewerberhotlinetop {
	background: transparent url(../../images/icons/tel.png) no-repeat left;
}

.bewerberhotlinetop span {
 display:block;
}

.bewerberhotlinejobs a {
  color: $black;
  text-decoration: none;
}

.bewerberhotlinejobs .telefonnummer {
 font-size:1.1em;
}

#responsive-menu {
  display:inline;
}

.is-stuck {
	background: $white;
}
.is-stuck .seiten-logo {
	background-image: url(../../images/logo_klein.gif);
	background-size: 294px auto;
	height: 25px;
	width: 294px;	
}
.is-stuck .dropdown > li > a {
	color: $black;
	margin: 0;
}
.is-stuck .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
	border-color: $black transparent transparent;
}
.is-stuck ul.is-dropdown-submenu  {	
	margin: 1px 0 0 0;
}
.top-bar .is-dropdown-submenu  li a {
	color: $white;
}

/** Startseite Aussnahmen **/
.welcome .top-bar, #anmelden_index .top-bar {
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow:	none;
}
.welcome .is-stuck {
	-webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.35);
	-moz-box-shadow:    0px 3px 3px 0px rgba(0, 0, 0, 0.35);
	box-shadow:         0px 3px 3px 0px rgba(0, 0, 0, 0.35);	
}
.welcome .seiten-logo, #anmelden_index .seiten-logo {
	background: url(../../images/logo_handwerker.png) no-repeat left top;
	height: 52px;
	width: 300px;
}
.welcome .is-stuck .seiten-logo, #anmelden_index .is-stuck .seiten-logo {
	background: url(../../images/logo_klein.gif) no-repeat left top;
	height: 25px;
	background-size: 294px auto;
	width: 294px;
    margin-left: 6px;	/* Springen unterdrücken */
}
/*
.welcome .top-bar .dropdown li a, 
#anmelden_index .top-bar .dropdown li a {
	color: $white;
}

.welcome .is-stuck .dropdown li a, 
#anmelden_index .is-stuck .dropdown li a {
	color: $black;
}
*/

/*.welcome .top-bar .dropdown.menu > li.is-dropdown-submenu-parent > a::after,
#anmelden_index  .top-bar .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
	border-color: $white transparent transparent;
}*/
.welcome .top-bar ul.is-dropdown-submenu,
#anmelden_index  .top-bar ul.is-dropdown-submenu {
	margin-top: 19px;	
}
#anmelden_index .top-bar .dropdown > li > a,
.welcome .top-bar .dropdown > li > a {
	margin: 10px 0;
}
.welcome .is-stuck .dropdown > li > a {
	color: $black;
	margin: 0;
}
.welcome .is-stuck .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
	border-color: $black transparent transparent;
}
.welcome .is-stuck ul.is-dropdown-submenu {
	margin-top: 1px;
}

/** Icons **/
.top-bar .intern .submenu > li > a {
	background-repeat: no-repeat;
	background-position: 10px center;
	padding-left: 40px;
}
.top-bar .intern .uebersicht > a {
	background-image: url(../../images/icons/submenue/uebersicht.gif);	
}
.top-bar .intern .pakete > a {
	background-image: url(../../images/icons/submenue/pakete.gif);
}
.top-bar .intern .meine-anzeigen > a {
	background-image: url(../../images/icons/submenue/meine_anzeigen.gif);
}
.top-bar .intern .meine-daten  > a{
	background-image: url(../../images/icons/submenue/meine_daten.gif);
}
.top-bar .intern .abmelden > a {
	background-image: url(../../images/icons/submenue/abmelden.gif);
}
.top-bar .intern .pw-aendern > a {
	background-image: url(../../images/icons/submenue/schluessel.gif);
}
.top-bar .intern .admin {
	background-color: get-color('primary');
}
.top-bar .intern .admin > a{
	background-image: url(../../images/icons/submenue/admin.gif);
}
/*
.welcome .top-bar .dropdown li.admin a {
	color: $white;
}
*/
.welcome .is-stuck .dropdown li.admin a {
	color: $black;
}
.top-bar .intern .hilfe > a {
	background-image: url(../../images/icons/submenue/hilfe.gif);
}
.top-bar .intern .kontakt > a{
	background-image: url(../../images/icons/submenue/kontakt.gif);
}
.top-bar .intern .bewerbungen > a{
	background-image: url(../../images/icons/submenue/bewerbungen.gif);
}

/** Media Queries **/
@media screen and (max-width: 900px){
	
	.top-bar .top-bar-title {	
		float: left;
	}
	
	.top-bar .top-bar-right {	
		float: none;
	}	
	
	.top-bar-title {
		padding-bottom: 0;
	}
	.top-bar ul.is-dropdown-submenu {
		margin-top: 1px;
	}
}

@media screen and (max-width: 799px) {	

	#responsive-menu {
	  display:block;
	}

	.menu-icon.dark::after {
		background: $white;
		box-shadow: 0 7px 0 $white, 0 14px 0 $white;
	}
	.menu-icon.dark {
		background: $black;
		padding: 10px 10px 6px 10px;
		border: 10px solid $black;
	}
	.top-bar-title {
		display: inline;
		padding: 1rem;
	}
	.top-bar-title > span {
		position: absolute;
		right: 15px;
		top: 15px;
	}
	.top-bar .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
		border-color: $white transparent transparent;
	}
	.welcome .seiten-logo,
	#anmelden_index .seiten-logo,	
	.seiten-logo {
		background-size: 260px auto;
		height: 44px;
		width: 260px;
	}
	.is-stuck .seiten-logo {
		background-size: 260px auto;
		width: 260px;
	}
	.top-bar .dropdown li a {
		color: $white;
	}	
	.top-bar-right .menu > li {
		display: block;	
	}
	.top-bar-right > div > .dropdown {
		background: $black;	
	}
	.is-stuck .top-bar-right > div > .dropdown {
		background: $white;	
	}
	.welcome .top-bar ul.is-dropdown-submenu,	
	.top-bar ul.is-dropdown-submenu {
		position: static;
		box-shadow: none;
		background: $white;
		margin: 0;
	}
	.is-stuck ul.is-dropdown-submenu {
		background: $black;	
	}
	.welcome .top-bar .dropdown li .submenu li a,
	.top-bar .submenu li a {
		color: $black;
	}
	.welcome .top-bar .dropdown > li > a,
	.top-bar .dropdown > li > a {
		margin: 0;	
	}
	.top-bar .submenu li a {
		padding: 5px 10px;
	}
}
@media screen and (max-width: 799px) {
	.sticky.is-anchored.is-at-bottom{
		top: 0 !important;
	}
}
@media screen and (max-width: 360px) {
	.welcome .seiten-logo,
	#anmelden_index .seiten-logo,
	.seiten-logo	{
		background-size: 220px auto;
		width: 220px;
		height: 25px;
	}
	.top-bar-title > span {
		top: 10px;
	}
	
	body > header.abstand {
		padding: 130px 0 0 0;
	}
	
}

