
@font-face {
    font-family: 'montserratbold';
    src: url('../../fonts/montserrat/montserrat-medium-webfont.eot');
    src: url('../../fonts/montserrat/montserrat-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/montserrat/montserrat-medium-webfont.woff2') format('woff2'),
         url('../../fonts/montserrat/montserrat-medium-webfont.woff') format('woff'),
         url('../../fonts/montserrat/montserrat-medium-webfont.ttf') format('truetype'),
         url('../../fonts/montserrat/montserrat-medium-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'montserratitalic';
    src: url('../../fonts/montserrat/montserrat-italic-webfont.eot');
    src: url('../../fonts/montserrat/montserrat-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/montserrat/montserrat-italic-webfont.woff2') format('woff2'),
         url('../../fonts/montserrat/montserrat-italic-webfont.woff') format('woff'),
         url('../../fonts/montserrat/montserrat-italic-webfont.ttf') format('truetype'),
         url('../../fonts/montserrat/montserrat-italic-webfont.svg#montserratitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'montserratregular';
    src: url('../../fonts/montserrat/montserrat-regular-webfont.eot');
    src: url('../../fonts/montserrat/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/montserrat/montserrat-regular-webfont.woff2') format('woff2'),
         url('../../fonts/montserrat/montserrat-regular-webfont.woff') format('woff'),
         url('../../fonts/montserrat/montserrat-regular-webfont.ttf') format('truetype'),
         url('../../fonts/montserrat/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'roboto_slabbold';
    src: url('../../fonts/roboto/RobotoSlab-Bold-webfont.eot');
    src: url('../../fonts/roboto/RobotoSlab-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/roboto/RobotoSlab-Bold-webfont.woff') format('woff'),
         url('../../fonts/roboto/RobotoSlab-Bold-webfont.ttf') format('truetype'),
         url('../../fonts/roboto/RobotoSlab-Bold-webfont.svg#roboto_slabbold') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'roboto_slabregular';
    src: url('../../fonts/roboto/RobotoSlab-Regular-webfont.eot');
    src: url('../../fonts/roboto/RobotoSlab-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/roboto/RobotoSlab-Regular-webfont.woff') format('woff'),
         url('../../fonts/roboto/RobotoSlab-Regular-webfont.ttf') format('truetype'),
         url('../../fonts/roboto/RobotoSlab-Regular-webfont.svg#roboto_slabregular') format('svg');
    font-weight: normal;
    font-style: normal;

}