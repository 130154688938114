.magazin-uebersicht header {
	max-height: none;
}
.magazin-uebersicht header .row:first-child {
	margin-bottom: 55px;
}
.magazin-uebersicht .ueberschrift h1, .magazin-uebersicht .ueberschrift h2 {
	margin-bottom: 0;
}
.magazin-uebersicht header .ueberschrift div:nth-child(2) .inhalt p {
	font-family: $font-bold-montserrat;
	font-size: 1rem;
	text-align: left;
	line-height: 30px;
}
.magazin-uebersicht .bereich-news {
	background: $grau-blau;
	margin:60px 0;
	padding: 60px 15px 45px 15px;
}
.magazin-uebersicht header,
.magazin-uebersicht .bereich-infos {
	padding: 0 15px;
}
.magazin-uebersicht .bereich-news .link-boxen h3 {
	font-size: 22px;
}
.magazin-uebersicht .bereich-news .inhalt-teaser,
.magazin-uebersicht .bereich-news .link-boxen {
	margin-bottom: 0;
	height: 100%;
}
.magazin .bereich-inhalt .row .columns > div,
.magazin .link-boxen,
.magazin-uebersicht .bereich-infos .row:nth-child(2) .columns > div {
	height: 100%;
	margin: 0;
}
.magazin .link-boxen,
.magazin-uebersicht .bereich-news .link-boxen {
	position: relative;
	padding-bottom: 45px;
}
.magazin .link-boxen .weiter,
.magazin-uebersicht .bereich-news .link-boxen .weiter {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}
/*.magazin .link-boxen .weiter:before {
	content: '';
	height: 30px;
	background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
	background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
	background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
	position: absolute;
	left: 0;
	top: -35px;
	width: 100%;
}*/
.magazin .top-meldung .link-boxen {
	padding-bottom: 0;
}
.magazin .top-meldung .link-boxen h2 {
	font-size: 22px;
	line-height: 30px;
	padding-right: 80px;
}
/*.magazin .top-meldung .link-boxen .weiter:before {
	content: none;
}*/
.bereich-inhalt .wrapper > div:nth-child(2) > div,.bereich-inhalt .wrapper > div:nth-child(3) > div {
	margin-bottom:15px;
}
.magazin-uebersicht .bereich-infos > .wrapper > .row > .columns,
.magazin-uebersicht .bereich-news > .wrapper > .row > .columns {
	margin-bottom: 30px;
}
.magazin-uebersicht .bereich-infos .inhalt-menue {
	background: $grau-blau;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.23); 
	padding: 20px 10px;
}
.magazin-uebersicht .bereich-infos .inhalt-menue h3 {
	font-size: 18px;
	position: relative;
	margin-bottom: 30px;
}
.magazin-uebersicht .bereich-infos .inhalt-menue h3:after {
	content: '';
	position: absolute;
	right: 0;
	top: -5px;
	background-image: url(../../images/icons/info_icons.png);
	background-repeat: no-repeat;	
}
.icon-birne h3:after {
	background-position: 0 bottom;
	height: 42px;
	width: 38px;
}
.icon-uhr h3:after {
	background-position: -38px bottom;
	height: 34px;
	width: 34px;
}
.icon-blatt h3:after {
	background-position: -72px bottom;
	height: 34px;
	width: 34px;
}
.icon-graph h3:after {
	background-position: -106px bottom;
	height: 42px;
	width: 36px;
}
.magazin-uebersicht .bereich-infos > .wrapper > .row {
	margin: 0 -15px;
}
.magazin-uebersicht .bereich-infos > .wrapper > .row > .columns {
	padding: 0 15px;
}
.magazin-uebersicht .bereich-infos .inhalt-menue ul {
	list-style: none;
	font-size: 20px;
}
.magazin-uebersicht .bereich-infos .inhalt-menue ul li a {
	font-size: 18px;
}
.magazin .bereich-inhalt .wrapper .row {
	margin-bottom: $standardabstand;
}

@media screen and (max-width:1023px)
{
	.bereich-inhalt .wrapper > div:nth-child(2) > div,.bereich-inhalt .wrapper > div:nth-child(3) > div {
		margin-bottom:30px;
	}
	.link-boxen p.bild a img {
		width: 100%;
	}	
}

/*.icon-birne
.icon-uhr
.icon-blatt
.icon-graph*/