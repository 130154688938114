.bereich-staedte {
	background: $grau-blau;
	margin:60px 0;
	padding: 60px 15px;
}
.bereich-staedte .wrapper > .row:nth-child(2),
.bereich-staedte .wrapper > .row:nth-child(3) {
	margin: 0 -15px;
}
.bereich-staedte .wrapper > .row:nth-child(2) .columns,
.bereich-staedte .wrapper > .row:nth-child(3) .columns {
	padding: 0 15px;
}
.startlisteland {
	list-style: none;
	margin: 0 0 15px 0;
}
.startlisteland > li {
	margin: 0 0 15px 0;
}
.startlisteland > li:last-child {
	margin-bottom: 0;
}
.startstadtbild {
	margin: 0 0 15px 0;
}
.startstadtbild a {
	border: 2px transparent solid;
	display: block;
}
.startstadtbild a img {
	width: 100%;
}
.startstadtbild a:hover {
	border: 2px $marine-blau solid;
}
@media screen and (max-width: 600px) {
	.bereich-staedte {
		background: $grau-blau;
		margin:30px 0;
		padding: 30px 15px;
	}
}

/*	
	.staedteanzeigen_index > h1, .staedteanzeigen_index > h2 {
		margin:0;
		margin-bottom:10px;
	}
	.staedteanzeigen_index > h2,
	.news_index > h2{
		margin-bottom:50px;
	}
.startlisteland {
		margin:0 0 $standardabstand 0;
	}
	.staedteanzeigen_index_wrapper{
		clear:both;
		background: $grau-blau;
		margin:60px 0;
	}
	.staedteanzeigen_index{
		max-width:1280px;
		margin:60px auto 0 auto;
	}
	.staedte_teaser a img{
		border:2px solid $grau-blau;
		margin-right:10px;
	}
	.staedte_teaser a:hover img{
		border:2px solid $marine-blau;
	}
	.staedte_links{
		margin:60px 0;
	}
	.staedte_links ul{
		list-style-type:none;
	}
	.staedte_links ul li ul li > a{
		font-family: $font-regular-montserrat;
	}
	
@media (min-width: 320px) {	
	.staedteanzeigen_index > h2 {
		margin-bottom: 20px;
	}
			.staedte_teaser > div{
				padding:0 0 0 8px;
			}
			.staedte_links{
				margin:20px 0 20px 0;
			}
			.staedte_links ul {
				margin: 0 20px 0 20px;
			}			
	
}
@media (min-width: 600px) {	
	.staedteanzeigen_index > h2 {
		margin-bottom: $standardabstand;
	}
}
@media (min-width: 800px) {
	.staedte_teaser, .staedte_links{
		margin:0;
	}
	.staedte_links {
	   padding: 0 0 $standardabstand 10px;
	}
	.staedteanzeigen_index_wrapper{
		padding-right:10px;
	}
			.staedte_links ul {
				margin: 0 20px 0 0;
			}
			.staedte_teaser a img {
				margin-bottom: 20px;
			}	
}
*/	