/*** Montserrat > Fließtext, Unterüberschriften ***/

	$font-regular-montserrat: "montserratregular", Arial, Helvetica,  sans-serif;
	$font-bold-montserrat: "montserratbold", Arial, Helvetica,  sans-serif;
	$font-italic-montserrat: "montserratitalic", Arial, Helvetica,  sans-serif;
	

/*** Roboto > Hauptüberschriften + 2. Zeile (wenn .text-center) ***/

	$font-regular-roboto: "roboto_slabregular", Arial, Helvetica,  sans-serif;
	$font-bold-roboto: "roboto_slabbold", Arial, Helvetica,  sans-serif;

	
$breite-100pz: 100%;
$höhe-100pz: 100%;
$standardabstand: 30px;

$standardabstand768: 20px;
$standardabstandvertikal: 20px;


body, p, span, .column, .columns, .row .row, .button, ul, li, label, form, input, fieldset, legend, [type="color"], [type="date"], [type="datetime-local"], [type="datetime"], [type="email"], [type="month"], [type="number"], [type="password"], [type="search"], [type="tel"], [type="text"], [type="time"], [type="url"], [type="week"], textarea, .accordion-title {
	padding: 0;
	margin: 0;
	font-size:16px;	
}
.column, .columns{
	min-height: 1px;
}
h1{
	font-size:40px;	
}
h2{
	font-size:23px;
}
h3{
	font-family: $font-bold-montserrat;
}

h1.text-center{
	font-family:$font-bold-roboto;
	font-weight:normal;
}
h2.text-center{
	font-family:$font-regular-roboto;
	font-weight:normal;
}
h2 a{
	font-size:20px;
}
a,strong,b {
	font-family: $font-bold-montserrat;
	font-weight:normal;
}
a:hover{
	text-decoration:underline;
}

p {
    line-height: 1.4;
}
main{
	padding: 87px 0 60px 0;
	background: #e5e4ea;
	background: -moz-linear-gradient(top,  #e5e4ea 0%, #ffffff 100%);
	background: -webkit-linear-gradient(top,  #e5e4ea 0%,#ffffff 100%);
	background: linear-gradient(to bottom,  #e5e4ea 0%,#ffffff 100%);
	background-size: 100% 150px;
	background-repeat: no-repeat;	
	background-position:0 60px;
}
main.stellenanzeigen_uebersicht{
	padding:0 15px;
	background-position:0 0;
}
.clear{
	clear:both;
}
.button, .button2{
	font-family: $font-regular-montserrat;
	font-weight:normal;
	padding:9px;
}
.button2{
	background:$white;
	border:1px solid $medium-gray;
}
input[type="text"], 
input[type="email"],
input[type="number"], 
input[type="password"],
input[type="tel"] {
	padding:0 10px;
}
input.datumsfeld {
	padding-right: 40px;
}
.fieldset {
    margin: 0;
    padding: 0;
}

/* Eingabefelder */
.abstandvertikal {
  padding: 0 0 $standardabstandvertikal 0;
}

#bewerberphasejahre {
	display: none;
}

body.welcome main{
	padding:0;
	background:none;
}

.form-error {
    margin-top: 0
}
.ohne-abstand {
	margin-bottom: 0;
}
.help-text {
    margin-top: 0;
}
.wrapper {
	margin: 0 auto;
	max-width: 75rem;
}
/*** Header START ***/
	@import "allgemein";
	@import "topbar";
	@import "header";
	@import "rootline";
/*** Navigation Startseite START ***/
/*** Header ENDE ***/

/*** Suche START ***/
	.small-centered {
		margin:0 auto;
	}
	.chosen-container {
		height: 2.4375rem;
		margin-bottom: 15px;
	}
	.chosen-container-multi .chosen-choices {
		height: $höhe-100pz;
	}
	.chosen-container-multi .chosen-choices li.search-choice{
		margin-top: 8px;
	}
	.chosen-container-multi .chosen-choices li.search-field {
		margin-top: 5px;
	}

	.search-form-wrapper .row div form .row div .row{
		margin:0 auto !important;
		max-width:75rem;
	}
	.is-invalid-label .chosen-choices {
		@include form-input-error;
		background-image: none;
	}
	
	/* label disabled */
	.chkboxdisabled {
	  color: $medium-gray;
	}
	
	#kategorieSucheLoeschen {
		display: none;
		cursor:pointer;
	}
	
	#zeigefilter a.button:hover {
		text-decoration: line-through;
	}
	
	.stellenanzeigen_uebersicht .left #zeigefilter {
		margin-top: 20px;
	}
	.stellenanzeigen_uebersicht .left #zeigefilter a.button {
		margin-bottom: 10px;
		margin-top: 0;
	}
	
	.urlparams {
		font-family: $font-regular-montserrat;
	}
	
	.markiert .urlparams {
		font-family: $font-bold-montserrat;
	}
	

	
/*** Suche ENDE ***/



/*** Stellenanzeigen Startseite START ***/
	@import "bereich-stellenanzeigen";
/*** Stellenanzeigen Startseite ENDE ***/


/*** Staedteanzeigen Startseite START ***/
	@import "bereich-staedte";
/*** Staedteanzeigen Startseite ENDE ***/


/*** News Startseite START ***/
	@import "bereich-news";
	
/*** News Startseite ENDE ***/


/*** Top Berufe Startseite START ***/

	
/*** Top Berufe Startseite ENDE ***/

/*** Banner Startseite START ***/

	.banner_index{
		background: #FE970E;
		text-align: center;
	}

/*** Banner Startseite ENDE ***/


/*** Footer START ***/
	@import "footer";
/*** Footer ENDE ***/


/*** Auf Stellenanzeige bewerben START ***/

	.fieldset #kontaktAngaben{
		padding:20px 15px 30px 15px;
		background-color:beige;
		margin:15px 0;
	}
	p#zusatzanschreiben{
		background-color:#B2C937;
		padding:15px;
	}
	#anschreiben{
		padding:5px;
	}
	span.erforderlicheAngabe{
		color: #d54602;
	}
	span.optionaleAngabe{
		color:#A09F9F;
	}

	.row .bewerbungStellenanzeige {
		margin:0 -15px;
		padding:0 0 $standardabstand 0;
		border-bottom: 1px solid #b9b9b9;
	}
	.bewerbungStellenanzeige .columns {
		padding: 0 15px;
	}
	.profillabelzeile, .profileintragzeile{
		display:inline-block;
	}
	.bewerbungbtn{
		font-weight:bold;
		line-height:1.5rem;
		font-size:20px;
		color:white;
	}
	.bewerbungbtnbox{
		text-align:right;
	}
	#standardTextbox{
		margin-top:8px;
	}
/*** Auf Stellenanzeige bewerben ENDE ***/


/*** Pagination START ***/
	.pagination{
		margin-top: $standardabstand;
	}
	.pagination .current {
		background: none;
		color: foreground($black);
		border: 1px solid $medium-gray;
		padding: 0;
		margin: 2px;
		width: 25px;
		height: 25px;
		line-height:25px;
		border-radius: 25px;
		-moz-border-radius: 25px;
		text-align: center;
	}
	.pagination li {
		margin-right: 0;
		/*border-radius: 0;*/
	}
	
	#anzeigen .pagination li a:hover,#anzeigen .pagination li a:focus {
		text-decoration: none;
	}
	
	@media screen and (max-width:599px) {
		#anzeigen .pagination li {
			font-size: 1.8rem;
			background: #e6e6e6;
		}
		#anzeigen .pagination li.unavailable {
			padding: 0.1875rem 0.625rem;
			background: transparent;
		}
		#anzeigen .pagination li.unavailable span {
			font-size: inherit;
			color: #e6e6e6;
		}
		#anzeigen .pagination li.zurueck {
			float: left;
		}
		#anzeigen .pagination li.vor {
			float: right;
		}
		#anzeigen .pagination {
			overflow: hidden;
		}
	}
	
/*** Pagination ENDE ***/


/*** Interner Bereich START ***/
	
	body.intern main{
		background: #e5e4ea; /* Old browsers */
		background: -moz-linear-gradient(top,  #e5e4ea 0%, #ffffff 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  #e5e4ea 0%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  #e5e4ea 0%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5e4ea', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
		background-size: 100% 150px;
	background-repeat: no-repeat;
	}
	
	/*** Login START ***/	
	@import "login";
	/*** Login ENDE ***/
	
	
	/*** Registrierung START ***/
		body#anmelden_index .registrieren{
			padding:$standardabstand 0 0 0;
			margin-top:$standardabstand;
			border-top:1px solid $black;
		}
		.registrierungLink {
			margin:10px 0 0 0;
		}
		.registrierungLink .button{
			margin-right: 15px;
		}
		select[name='idBranche'] option{
		 padding: 10px 2px; 
		}
		
	/*** Registrierung ENDE ***/	
	/*** Kostenlose Registrierung für Bewerber START ***/
	
		main #c28 > .inhalt > ul{
			margin-bottom:15px;
			border:1px solid $primary-color;
			padding:15px;
		}
	/*** Kostenlose Registrierung für Bewerber ENDE ***/
	
	body.intern a.anzeigen,
	body.intern a.bearbeiten,
	body.intern a.stellenanzeigeAktivInaktiv{
		display:inline-block;
		min-height:40px;
		margin:0 10px;
	}
	.stellenanzeige_logo{
		max-width:300px;
		position:absolute;
	}
	
	
	/*** Interner Bereich Meine Stellenanzeigen START ***/
		table { 
			border-collapse: collapse; 
			margin:$standardabstand 0;
		}
		tbody tr{
			border-spacing:1px;
		}
		table.hover tbody tr:hover {
			background-color: $white;
		}
		table thead{
			background: $grau-blau;
			font-family: $font-bold-montserrat;
			font-weight:normal;
			border:1px solid $grau-blau;
		}
		table.hover thead tr:hover  {
			background-color: $grau-blau;
		}
		table tbody tr:nth-child(2n), table.hover:not(.unstriped) tr:nth-of-type(2n):hover {
			background-color: $light-gray;
			border:1px solid $light-gray;
		}
		table.hover tbody tr:hover, table.hover:not(.unstriped) tr:nth-of-type(2n):hover{
			border:1px solid $medium-gray;
		}
		table tbody .datumTag{
			margin:0 10px 0 0;
			padding:0 10px 0 0;
			border-right:1px solid $black;
		}
		table tbody tr.zeileInaktiv .datumTag{
			border-right:1px solid $light-gray;
		}
		.chosen-container-single .chosen-single {
			padding: 6px 0 0 8px;
			height: 40px;
		}
	/*** Interner Bereich Meine Stellenanzeigen ENDE ***/
	
	
	/*** Interner Bereich Meine Daten START ***/
		fieldset {
			margin: $standardabstand 0 0 0;
			padding: 0 0 $standardabstand 0 !important;
			border: 0!important;
			border-bottom: 1px solid $dark-gray !important;
		}
		fieldset:last-child {
			border-bottom: none !important;
		}
		.fieldset legend {
			background: none;
			margin: $standardabstand 0 0 0;
			font-family: $font-bold-montserrat;
			font-size: 24px;
		}
		label{
			font-family: $font-bold-montserrat;
			font-weight:normal;
		}
		label input, label select, label textarea, .chosen-container-single .chosen-single{
			font-family: $font-regular-montserrat !important;
			font-weight:normal;
		}
		label textarea{
			line-height:1.4;
			padding: 5px;
		}
		.row.speichern{
			margin:$standardabstand 0 0 0;
		}
		input:checked ~ .switch-paddle{
			background:#2039ac;
		}
		.switch.small .switch-paddle {
			height: 1.2rem;
		}
		.switch.small .switch-paddle::after {
			width: 0.9rem;
			height: 0.7rem;
		}
		.switch {
			margin-bottom: 0;
		}		
		
		.hinweismeinedaten {
			margin: 0 0 $standardabstand 0;
		}
		
	/*** Interner Bereich Meine Daten ENDE ***/
		
	/*** Interner Bereich Meine Daten Uebersicht START ***/


	.auswahlUebersichtBlock.columns {
		padding: 0 15px;
		margin-bottom: 10px;
	}

	.auswahlUebersichtInhalt {
		border: 1px solid #c1c1c1;
	}	
	.auswahlUebersichtInhalt .auswahlTitel {
		font-weight:bold;
		font-size: 1.1em;
		background: #e6e6e6;
		padding: 10px 0;
		margin: 0 0 15px 0;
	}
	
	.auswahlUebersichtInhalt .auswahlBeschreibungen {
		padding: 10px 5px;
	}
	
	.auswahlUebersichtInhalt .button {
		margin-bottom: 10px;
		width:50%;
	}
	
	.auswahlUebersichtHinweis {
		margin-bottom: 40px;
	}
	
	/*** Interner Bereich Meine Daten Uebersicht ENDE ***/	
	
	/*** Intern Pakete Start ***/
	
	.paketAnzeige {
		padding: 10px;
	}
	main > .bereich-inhalt .spalte-inhalt > div:first-child > div{
		
		margin-bottom:20px;
	}
	main > .bereich-inhalt .paketAuswahl > div:nth-child(2) > p{
		padding-top:10px;
	}
	
	/*** Intern Pakete Ende ***/
	

	/*** Stellenanzeige anzeigen intern START ***/
		.standard_layout_anzeige .row.arbeitsort{
			margin-bottom:$standardabstand;
		}
		.standard_layout_anzeige .teaser .region,
		.standard_layout_anzeige .teaser .bundesland{
			display:inline-block;
			margin:0;
		}
	/*** Stellenanzeige anzeigen intern ENDE ***/

/*** Interner Bereich ENDE ***/


/*** Stellenanzeige Detail START ***/
	.head_stellenanzeige_detail h2{
		font-family: $font-bold-roboto;	
		font-weight:normal;
	}
	.head_stellenanzeige_detail_intern{
		margin-bottom:$standardabstand;
		padding-bottom:$standardabstand;
		border-bottom:2px dashed $dark-gray;
	}
	.head_stellenanzeige_detail_img{		
		margin-bottom:$standardabstand;
	}
	.stellenanzeige_detail_ort{
		padding-top:0;
	}
	.stellenanzeige_detail_beschreibung, .stellenanzeige_detail_beschreibung2, .stellenanzeige_detail_beschreibung3, .stellenanzeige_detail_bewerbungKontakt{
		margin-bottom:$standardabstand !important;
		padding-bottom:$standardabstand !important;
		border-bottom:2px dashed $dark-gray;
	}
	.row.stellenanzeige_detail_bewerbung{
		margin:0 0 $standardabstand 0;
	}
	.bereich-inhalt .stellenanzeige_detail_beschreibung2 ul,
	.bereich-inhalt .stellenanzeige_detail_beschreibung3 ul {
		margin: 0;
	}	
	.stellenanzeige_detail_beschreibung2 ul li,
	.stellenanzeige_detail_beschreibung3 ul li{
		list-style-type:square;
		margin: 0 0 5px 20px;
	}
	.stellenanzeige_detail_beschreibung3 h1 {
		font-size: 23px;
	}
	.stellenanzeige_detail_beschreibung2 h2 {
		font-weight:normal;
	}
	.stellenanzeige_detail_beschreibung2 .columns .row {
		margin: 0 -15px	
	}
	.stellenanzeige_detail_beschreibung2 .columns .row .columns {
		padding: 0 15px;
	}
	.datum{
		text-align:left;
		margin:0 0 20px 0;
	}
	.wrapper > .row > .stellenanzeigeZurueckOben {
		padding-bottom: 20px;
	}
	.stellenanzeigeZurueckOben .callout {	
		margin: 15px 0;
	}	
	.stellenanzeigeZurueckUnten {
		padding: 20px 0 20px 0;
	}
	.standard_layout_anzeige .teaser,
	.pdf_anzeige .teaser {
		margin-bottom:$standardabstand;
	}
	.standard_layout_anzeige .teaser h1,
	.pdf_anzeige .teaser h1	{
		font-size: 23px;
		margin: 0;
	}
/*** Stellenanzeige Detail ENDE ***/

/*** Kontakt Formular START ***/
	main .bereich-inhalt .inhalt .abstandvertikal  > .kf-erlaeuterung{
		margin-bottom:10px;
	}
/*** Kontakt Formular ENDE ***/


/*** Bewerbung Formular START ***/
	#weitererAnhang {
		color: $marine-blau;
		font-weight: bold;
		font-size: 15px;
		cursor: pointer;
	}
	.bewerbung-formular .accordion .accordion-title:before {
		color: $black;
		right: auto;
		left: 10px;
	}
	.bewerbung-formular .accordion .accordion-title:focus,
	.bewerbung-formular .accordion .accordion-title:hover {
		text-decoration: none;
	}
	.bewerbung-formular .callout {
		border: 0;
		margin-bottom: 0;
		padding-left: 30px;
		position: static;
	}
	.bewerbung-formular #alleAnhange > div {
		margin-bottom: 15px;
	}
	.bewerbung-formular .dateiname {
		position: relative;
	}
	.bewerbung-formular .dateiname button {
		font-size:100%;
		top:0;
	}
	.verlauf {
		text-align: right;
	}
	.verlauf .button {
		margin-top: 5px;
	}
	.verlauf .bar {
		background: $primary-color;
		height: 5px;
		width: 0%;
	}
	span.infobtn{
		float:right;
	}
	#bewerbungoptional {
		margin: 15px 0 0 0;
	}
	#bewerbungoptionalbtn{
		background-color:#999494;
		margin-bottom:15px;
	}
	#bewerbungoptionengroup p, #bewerbungoptionengroup label, #bewerbungoptionalbtn{
	color:white;
	}
	#bewerbungoptionengroup{
		background-color:#999494;
		padding:15px;
	}
	.optional{		
		font-family: $font-regular-montserrat;
		font-weight:normal;
	}
	[type="checkbox"] + label, [type="radio"] + label {
		vertical-align: top;
	}
	
	.row .bewerbungAngaben {
		margin-top: $standardabstand;
		margin-bottom: $standardabstand;
	}
	
	.bewerbungAngaben .fieldset {
		padding: $standardabstand 0;
		margin-bottom: $standardabstand;	
	}
	
	.bewerbungAngaben input[type="checkbox"]{
		margin:8px 10px 0 0;
	}
	
	select.anhangtyp option{
		 padding: 10px 2px; 
	}
	.bewerbung-formular fieldset {
		padding: 0;
		margin: 0 0 $standardabstand 0;
	}
	.bewerbung-formular select {
		margin: 0;
	}
	.bewerbung-formular .row {
		/*margin: 0 -15px 15px -15px;*/
	}
	.bewerbung-formular .row {
		margin: 0 -15px;
	}
	.bewerbung-formular .row .columns {
		padding: 0 15px;
	}
	.bewerber-checkbox {
		position: relative;
		padding-left: 20px;
		margin: 0 0 15px 0;
	}
	.bewerber-checkbox input {
		position: absolute;
		left: 0;
		top: 7px;
		margin: 0;
	}
	.hinweis-box {
		background:#e6e6e6;
		border:1px #c1c1c1 solid;
		padding:25px;
		margin: 30px 0;
	}
	.hinweis-box > p:nth-child(1){
		padding-bottom:10px;
	}
	
	@media screen and (max-width:600px) {
		.bewerbung-formular .row .columns {
			padding-bottom: 15px;
		}
		.bewerbung-formular .row .columns:last-child {
			padding-bottom: 0;
		}
	}
	
/*** Bewerbung Formular ENDE ***/

/*** Bewerbungen (Spaltenbreite) START ARBEITGEBER ***/

	.listeBewerbungen-spalte1 { width:30%; }
	.listeBewerbungen-spalte2 { width:20%; }
	.listeBewerbungen-spalte3 { width:25%; }
	.listeBewerbungen-spalte4 { width:20%; }
	.listeBewerbungen-spalte5 { width:5%; }
	
	.zelleBewerbung-spalte1 { width:30%; }
	.zelleBewerbung-spalte2 { width:30%; }
	.zelleBewerbung-spalte3 { width:10%; }
	.zelleBewerbung-spalte4 { width:20%; }
	.zelleBewerbung-spalte5 { width:5%; }
	.zelleBewerbung-spalte6 { width:5%; }
	.zelleBewerbung-spalte6 a { width: 40px; }

/*** Bewerbungen (Spaltenbreite) START BEWERBER ***/

	.listeBewerbungenBewerber-spalte1 { width:30%; }
	.listeBewerbungenBewerber-spalte2 { width:20%; }
	.listeBewerbungenBewerber-spalte3 { width:20%; }
	.listeBewerbungenBewerber-spalte4 { width:15%; }
	.listeBewerbungenBewerber-spalte5 { width:10%; }
	.listeBewerbungenBewerber-spalte5 { width:5%; }
	
	.zelleBewerbungBewerber-spalte1 { width:30%; }
	.zelleBewerbungBewerber-spalte2 { width:20%; }
	.zelleBewerbungBewerber-spalte3 { width:20%; }
	.zelleBewerbungBewerber-spalte4 { width:15%; }
	.zelleBewerbungBewerber-spalte5 { width:10%; }
	.zelleBewerbungBewerber-spalte6 { width:5%; }

/*** Bewerbungen (Spaltenbreite) ENDE ***/


/*** Suche Unterseiten START ***/

	#suche_unterseiten{
		background:$white;
		margin:87px 0 0 0;
		padding: $standardabstand 0;
	}

/*** Suche Unterseiten ENDE ***/


/*** Stellenanzeigen (Spaltenbreite) START ***/

	.listeStellenanzeige-spalte1 { width:5%; }
	.listeStellenanzeige-spalte2 { width:45%; }
	.listeStellenanzeige-spalte3 { width:15%; }
	.listeStellenanzeige-spalte4 { width:15%; }
	.listeStellenanzeige-spalte5 { width:20%; }

/*** Stellenanzeigen (Spaltenbreite) ENDE ***/

	.filter_stellenanzeigen{
		background: transparent;
		margin:$standardabstand 0 0 0;
	}
	.filter_stellenanzeigen .filter{
		border-left:1px solid $light-gray;
		border-right:1px solid $light-gray;
		border-top:1px solid $light-gray;
	}
	.no-js .filter_stellenanzeigen {
		position: static;
		transform: unset;
		transition: none;
	}
	.no-js .accordion-content {
		display: block;
	}
	.no-js .filter-trigger {
		display: none;
	}
	
	.filter_stellenanzeigen .filter.last {
		border-bottom:1px solid $light-gray;
	}
	
	.filter_stellenanzeigen .filter h2{
		padding:$standardabstand;
		font-family: $font-bold-montserrat;
		font-size:16px;
	}
	.filter_stellenanzeigen .filter .filter-content{
		padding:0px;
		padding:0 $standardabstand $standardabstand $standardabstand;
	}
	
	.inaktive-berufe .wrapper > ul,
	.filter_stellenanzeigen .filter ul.filter-content {
		list-style: none;
		margin: 0;
	}
	.inaktive-berufe .wrapper > ul > li,
	.filter_stellenanzeigen .filter ul.filter-content li {
		position: relative;
		padding: 0 0 5px 23px;
	}
	.inaktive-berufe .wrapper > ul > li input[type="checkbox"],
	.filter_stellenanzeigen .filter ul.filter-content li input[type="checkbox"] {
		position: absolute;
		left: 0;
		top: 6px;
		margin: 0;
	}
	.inaktive-berufe .wrapper > ul > li label,
	.filter_stellenanzeigen .filter ul.filter-content li label {
		margin: 0;
		line-height: 1.6;
		font-family: $font-regular-montserrat;
	}	
	
	.filter_stellenanzeigen .filter ul.filter-content li label.markiert {
	  	font-family:$font-bold-montserrat;
		font-weight:normal;
	}	
	
	p.button-zeile {
		margin: 0;
		padding: 20px 0;
		text-align: center;
	}
	.inaktive-berufe {
		border-top:1px solid $light-gray;
		height: 300px;
		overflow: auto;
	}
	.inaktive-berufe > .wrapper {
		padding: $standardabstand $standardabstand 0 $standardabstand;
	}

	.filter_stellenanzeigen .filter .inaktive-berufe .wrapper > h2 {
		padding: 0;
		margin-bottom: $standardabstand;
	}
	.inaktive-berufe .wrapper > ul {
		margin-bottom: $standardabstand;
	}
@media screen and (max-width:799px) {
	.inaktive-berufe > .wrapper {
		padding: $standardabstand 20px 0 20px;
	}
	.filter_stellenanzeigen {
		background: -moz-linear-gradient(top,  #e5e4ea 0%, #ffffff 100%);
		background: -webkit-linear-gradient(top,  #e5e4ea 0%,#ffffff 100%);
		background: linear-gradient(to bottom,  #e5e4ea 0%,#ffffff 100%);
		margin: 0;
		padding-top: 45px;
		z-index: 9999;
		display: none;
	}
	.no-js .filter_stellenanzeigen {
		display: block;
	}
	
	.filter_stellenanzeigen	.close-button {
		top: 0;
		color: $black;
		font-size: 2.5em;
		height: 45px;
		line-height: 45px;
	}
	.filter_stellenanzeigen	.close-button span {
		font-size: inherit;
	}
	.filter_stellenanzeigen .accordion-title::before {
		font-size: 2rem;
		margin-top: -20px;
	}
}
/*** Stellenanzeigen Filterfunktion ENDE ***/
	
	.zelleStellenanzeige-spalte8 button { 
	  cursor:pointer;	  
	}
	
	.zelleStellenanzeige-spalte8 button i { 
	  color: $marine-blau;
	  font-size: 30px;	  
	}
/*** Stellenanzeigen (Zellen) ENDE ***/
/*** DROP DOWN MENU RECHTS OBEN START**/

	nav #c116 .dropdown ul li{
	border-bottom:2px solid $dark-gray;
	}
	nav #c116 .dropdown li .is-submenu-item a{
	padding-left:40px;
	
	}
	
/***  DROP DOWN ENDE ***/
/*** Karriereratgeber START ***/
	/*main .inhalt-text h1 {
		font-size: 36px;
		font-family:$font-bold-roboto;
		font-weight:normal;	
		margin: 1.4rem 0 1.4rem 0;
		text-align: center;
	}
	main .inhalt-text h2{
		font-size: 1.25rem;
		margin: 1.4rem 0 0.5rem 0;
	}
	main .teaser-text .inhalt {
		font-family: $font-bold-montserrat;	
		border-left: 10px solid darkgrey;
		padding: 0 0 0 30px;
		font-size: 1.375rem;
	}*/
	main .bereich-inhalt #c215 > h1, main .bereich-inhalt #c209 > h1,main .bereich-inhalt #c234 > h1,main .bereich-inhalt #c221 > h1,main .bereich-inhalt #c216 > h1,main .bereich-inhalt #c67 > h1 {
				margin-top:15px;
			}
	main .bereich-inhalt .spalte-inhalt > div > h1,main .bereich-inhalt .spalte-inhalt > div > h2{
		margin-bottom:5px;
		margin-top:15px;
	}
	/*** Karriereratgeber ENDE ***/
	

/* tabelle preise in arbeitgeber */
.spalte-inhalt .inhalt-text{
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens:auto;
}

.ag-preistabelle.hover:not(.unstriped) tr:nth-of-type(2n):hover, .ag-preistabelle tbody tr:nth-child(2n) {
    background-color: #f2f2f2 !important;
    border: 1px solid  #f2f2f2 !important;
}
.ag-preistabelle tbody tr:nth-child(2n) {
    border-bottom: 0;
    background-color:  #f2f2f2 !important;
}


.ag-preistabelle {
	position:relative;
}
.ag-preistabelle tr {
    background-color: #f2f2f2;
    border: 1px solid #fff;
}
.ag-preistabelle td:nth-child(2n) {
    background-color: #fff;
    border: 1px solid #fff;
	width: 10px;
	padding:0;
}
.ag-preistabelle h1 {
	color: #2f9908;
	font-size: 48px;
	
}
.ag-preistabelle tr:nth-child(1) td:nth-child(3) {
	position:relative;
}


.ag-preistabelle tr:nth-child(1) td:nth-child(3) strong::before {
    content: '';
    position: absolute;
    border-bottom: 28px solid #2f9908;
    border-left: 28px solid transparent;
    border-right: 28px solid transparent;
    height: 0;
    width: 170%;
    z-index: -1;
    left: -35%;
}
.ag-preistabelle tr:nth-child(1) td:nth-child(3) strong {
    position: absolute;
    right: 0;
	top:0;
    display: block;
    font-size: 14px;
    font-weight: 700;
    height: 28px;
    line-height: 28px;
    color: #fff;
    z-index: 1;
    min-width: 80px;
    background: #2f9908;
    -webkit-transform: rotate(45deg) translate(23%,57%);
    transform: rotate(45deg) translate(23%,57%);
}

.ag-preistabelle td:nth-child(2), .ag-preistabelle td:nth-child(3) {
	border-right: 3px solid #2f9908;
}
.ag-preistabelle tr:nth-child(1) td:nth-child(3) {
	border-top: 3px solid #2f9908;
}
.ag-preistabelle tr:last-child td:nth-child(3) {
	border-bottom: 3px solid #2f9908;
}

/* tabelle preise in arbeitgeber */
/*** Karriereratgeber ENDE ***/
/*** Aktuelle Nachrichten START ***/
	main .inhalt-plugin-nachrichten .inhalt > ul > li{
	text-align:left;
	padding-top:10px;
	padding-bottom:10px;
	margin:10px;
	border-bottom:1px solid $light-gray;
	}
/*** Aktuelle Nachrichten ENDE ***/
/*** Karriereratgeber Detail START ***/
	.link-boxen {
		background: $white;
		margin-bottom:30px;
		font-size:20px;
		font-family:$font-bold-montserrat;
		-webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.4);
		-moz-box-shadow:    0px 3px 8px 0px rgba(0, 0, 0, 0.4);
		box-shadow:         0px 3px 8px 0px rgba(0, 0, 0, 0.4);
	}
	.link-boxen h1, .link-boxen h2, .link-boxen h3, 
	.link-boxen h4,	.link-boxen h5, .link-boxen h6 {
		padding: 10px;
		margin: 0;
		line-height: 30px;
	}
	.link-boxen p {
		padding: 0 10px 10px 10px;
	}
	.link-boxen p.bild {
		padding: 0;
	}
	.link-boxen p.weiter {
		text-align: right;
	}
	.link-boxen p, .link-boxen p.weiter .button {
		margin: 0;
	}
/*** Karriereratgeber Detail ENDE ***/



.moverhinweistext {
	cursor:help;
}
.zeileInaktiv {
	color: $medium-gray;
}
.switch-nein {
	right:6%
}
.kein-rahmen-links{
	border-left:0;
	border-radius:0;
}
.kein-rahmen-links:first-child{
	border-top-left-radius:0;
	border-bottom-left-radius:0;
}

/* weitere Stellenanzeigen */
.rechts_weitere_anzeigen{
	border: 1px solid $medium-gray;
	padding: 0;
	overflow-wrap: break-word;
	word-wrap: break-word;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
}
.rechts_weitere_anzeigen h2 {
   font-size: 1.25rem;
   background-color: $light-gray;
   padding: 10px;			   
}
.bewerbung .rechts_weitere_anzeigen h2 {
	margin-bottom: 10px;	
}
.rechts_weitere_anzeigen h3 {
	font-size: 1.25rem;
	padding: 10px;
}
.rechts_weitere_anzeigen ul {
	list-style-type: none;
}	

.rechts_weitere_anzeigen li {
	padding: 10px;
}
.rechts_weitere_anzeigen li:nth-child(odd) { 
	background-color: $light-gray;
}

.rechts_weitere_anzeigen .profil {
	padding: 10px;
}
.row .stellenanzeigeArbeitgeberinfo {
	margin-bottom: $standardabstand;
}

.bewerbunganhangliste.menu > li.is-dropdown-submenu-parent > a {
	padding-right: 40px;
}
.bewerbunganhangliste .menu > li > a {
	padding: 10px;
}

#zeigefilter a{
				display:block;
				position:relative;
				text-align:left;
				background:#f5f5f5;
				border-color:#555;
				}
#zeigefilter li{
				float:left;
				display:inline-block;
				box-sizing:border-box;
				padding: 0 5px;
				max-width:180px;
}	
#zeigefilter li a i{
				position: absolute;
				top: 1em;
				right: 8px;
}
#zeigefilter{
				margin:0 -5px;
}
#zeigefilter li a p{
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				padding-right:15px;
				font-size:0.75rem;
				color:#555;
}
#zeigefilter li:last-of-type a p{
				font-weight:bold;
				text-align:center;
}

/* 
Stellenanzeige Bewerbungsmöglichkeiten 
Zusatztext für sticky mobil
*/
.zeigeJetztBewerbenMobil {
 padding: 8px 5px;
}
#bwerbungdiv.is-anchored .zeigeJetztBewerbenMobil {
	display:none;
}
	

/*** Mediaqueries START ***/
		@media screen and (max-width: 468px) {	
			main > .bereich-inhalt fieldset label{
					/*font-size:14px;*/
				}
			}
		@media screen and (max-width:480px){
			#zeigefilter li{
				max-width:none;
				width:50%;
				}
		}
		@media screen and (min-width:481px) and (max-width:610px){
			#zeigefilter li{
				width:33.3%;
				}
		}
		@media screen and (min-width:611px) and (max-width:1016px){
			#zeigefilter li{
				width:25%;
				}
		}
		@media screen and (min-width:1017px){
			#zeigefilter li{
				width:20%;
				}
		}
		
		@media screen and (max-width: 650px) {	
			main > .bereich-inhalt .columns .row .medium-2{
					margin:10px 20px 10px 0;
				}
			}				
			
		@media (min-width: 320px) {	
			.stellenanzeigen_index > h2, .news_index > h2 {
				margin-bottom: 20px;
			}
			.stellenanzeigen_index_text h2{
				/* margin:10px 0 0 0; */
				margin:0;
			}
			.stellenanzeigen_index_text {
				padding: 0;
			}
			div.top_berufe {
				margin: 60px 0 20px 0;
			}
			.news_index_wrapper .left, .stellenanzeigen_index .left, .karriereratgeber .left {
				padding-right: 20px;
				padding-left: 20px;
			}
			.news_index_liste a {
				padding: 20px 40px 20px 0;
				background: #fff url(../../images/icons/pfeil_news_index.gif) no-repeat right;
			}
			.news_index_liste a:hover {
				background: #e6e6e6 url(../../images/icons/pfeil_news_index_hover.gif) no-repeat right;
			}
			.left.columns{
				float:none;
			}			
			.news_index_links.column:last-child:not(:first-child), 
			.news_index_links.columns:last-child:not(:first-child) {
				float: none;
			}
			.news_index_links {
				margin: 100px 0 20px 0;
			}
			.news_index_wrapper{
				background:$white;
				clear:both;
			}
			.news_index_wrapper.column:last-child:not(:first-child), 
			.news_index_wrapper.columns:last-child:not(:first-child) {
				float: none;
			}
			.news_index_wrapper a.button, .news_index_links ul li a.button {
				float: none;
			}

			/*.left .start_item > div {
				text-align: left;
				margin:$standardabstand 0 0 0;
			}	*/		
			label.abstand, label.abstand-links{
				margin:0;
			}
			body.bewerbung h2{
				margin-bottom: 50px;
			}			
			body #c82,#c83{
			margin-top:15px;
			margin-bottom:15px;
			}

			.jetzt_bewerben1 a.button{
				margin: $standardabstand 0;
			}
			.stellenanzeige_detail_bewerbungKontakt div:last-child, .stellenanzeige_detail_bewerbungKontakt div:first-child {
				padding: 0;
			}
			.head_stellenanzeige_detail_img img:last-child{
				opacity: 0.00;
				filter: alpha(opacity =0);
			}	
			body.bewerbung main{
				padding:87px 0 0 0;
			}
			.row.bewerbungArbeitgeberinfo{
				margin:0 0 $standardabstand 0;
			}
		}
		
		@media (min-width: 375px) {	
		}
		@media (max-width: 599px){
		h1	{
		font-size:38px;
			}
		}
		
		@media (min-width: 600px) {	
			.stellenanzeigen_index_text,.bereich-stellenanzeigen .inhalt-plugin-stellenanzeigen .inhalt .row > .columns:nth-child(2) {
				padding: 0 10px;
			}
			.stellenanzeigen_index > h2, .news_index > h2 {
				margin-bottom: $standardabstand;
			}
			.stellenanzeigen_index .button{
				width:auto;
			}					
			label.abstand{
				margin:0 10px 0 0;
			}	
			label.abstand-links {
				margin:0 0 0 10px;
			}
			.row.bewerbungArbeitgeberinfo{
				padding:0 0 0 20px;
			}			
			.head_stellenanzeige_detail > p,
			.stellenanzeige_detail_ort{
				display:inline-block;
			}	
			.head_stellenanzeige_detail_img {
				margin-bottom: 0;
			}
			.head_stellenanzeige_detail_img img:last-child{
				opacity: 1;
				filter: alpha(opacity =100);
			}
			.filter_stellenanzeigen{
				-webkit-hyphens: auto;
				-ms-hyphens: auto;
				hyphens:auto;
				margin-bottom:15px;
			}
		}
		
		@media (min-width: 667px) {		
		}
		
		@media (min-width: 720px) {	
		}
		
		@media (min-width: 768px) {				

		}
		
		@media screen and (max-width: 799px) {
			.filter_stellenanzeigen .filter.last{
				/*margin-left:15px;*/
			}
			
			.link-boxen h1 a, .link-boxen h2 a, .link-boxen h3 a, .link-boxen h4 a, .link-boxen h5 a, .link-boxen h6 a{
				
				font-size:18px;
			}
			main .bereich-news #c131 div h3{
			text-align:center;
			}
			
			.filter_stellenanzeigen .filter h2{
				padding:$standardabstand768;
				/*font-size:14px;*/
			}
			.filter_stellenanzeigen .filter .filter-content{
				padding:0 $standardabstand $standardabstand $standardabstand768;
				font-size:14px;
			}
			main .bereich-inhalt #c215 > h1, main .bereich-inhalt #c209 > h1,main .bereich-inhalt #c234 > h1,main .bereich-inhalt #c221 > h1,main .bereich-inhalt #c216 > h1,main .bereich-inhalt #c67 > h1 {
				font-size:30px;
			}
			#bwerbungdiv.sticky.is-anchored {
				border:none;
				background-color:transparent;
			}
			#bwerbungdiv{
				border:1px solid #555;
				background:#f5f5f5;
				max-width: none !important;
				left:0;
				padding: 5px 10px;
			}
			
			#bwerbungdiv .row {
				margin: 0 -5px;
			}
			
			#bwerbungdiv .row .columns {
				padding: 8px 5px;
			}
			
			/*
			#bwerbungdiv a {
				width: 100%;
			
			}
			*/
			
			body.bewerbung h1{
				font-size:25px;
			}	
			
			body.bewerbung h2{
				font-size:23px;
			}	
			
			
			/*#bwerbungdiv a:nth-child(1){
			width:50%;
			margin-right:5px;
			}
			#bwerbungdiv a:nth-child(2){
			width:25%;
			}
			#bwerbungdiv a:nth-child(3){
			width:20%;
			}*/
		}
		@media screen and (min-width: 800px) {
		
			#bwerbungdiv{
				position:static;
			}
			
			.stellenanzeigen_index > h2{
				margin-bottom:50px;
			}
			.news_index_wrapper {
				padding-right:10px;
			}
			.news_index_wrapper .left, .stellenanzeigen_index .left {
				padding-right: 10px;
				padding-left: 0;
			}
			.news_index_wrapper .left{
				padding-left: 10px;
			}
			.left.columns{
				float:left;
			}
			.news_index_links.column:last-child:not(:first-child), 
			.news_index_links.columns:last-child:not(:first-child) {
				float: right;
			}
			.news_index_links {
				margin: 0 0 20px 0;
			}
			.news_index_wrapper.column:last-child:not(:first-child), 
			.news_index_wrapper.columns:last-child:not(:first-child) {
				float: right;
			}
			.news_index_wrapper a.button, .news_index_links ul li a.button {
				float: right;
			}

			div.banner_index{			
				margin: $standardabstand 0;
			}
			div.top_berufe {
				margin: $standardabstand 0;
			}

			.head_stellenanzeige_detail_img {
				margin-bottom: $standardabstand;
			}
			.jetzt_bewerben1 a.button {
				margin: 10px 0;
			}
		}
		@media screen and (min-width: 800px) and (max-width:1200px) {
			.filter_stellenanzeigen{
			padding-left:5px;
			
			}
		}
		
		@media (max-width: 1023px) {
			.topberufe {
				width:49%;
				float:left;
			}
			.startseiteanzeige {
				width:49%;
				float:right;
				background:$white;
				border:1px solid $medium-gray;
				padding:20px;
				margin:0 0 20px 0;
				text-align:center;
			}
			 	
		}		
		
		@media (min-width: 1024px) {
			div.top_berufe, div.banner_index {
				margin: 0 0 20px 0;
			}
			.top_berufe ul li a img {
				display: block;
				padding:0 0 10px 0;
			}
			.row.bewerbungArbeitgeberinfo{
				margin:0;
			}
		}
		
		@media (min-width: 1280px) {		
			.top_berufe ul li a img {
				display: inline-block;
				padding:0 20px 0 0;
			}	
			body.bewerbung main{
				padding:87px 0 0 0;
			}
		}
		
		@media screen and (max-width: 600px) {
			.stellenanzeige_detail_beschreibung2 .columns .row .columns:first-child {
				margin-bottom: $standardabstand;
			}
			.stellenanzeigen_index_text h2 {
				margin-top:5px;
			}
		}		
		
		@media screen and (max-width: 480px) {
			.topberufe,
			.startseiteanzeige {		
				float: none;
				width: 100%;
			}
		}		

/*** Mediaqueries ENDE ***/
@import "magazin";
@import "paketerwerb";
@import "nutzerinfo";

.ausbildung {
	background: $marine-blau;
	border-radius: $global-radius;
	display: inline-block;
	color: $white;
	text-align: center;	
	padding: 5px;
	font-size: 14px;
	margin: -15px 0 15px 0;	
}
.datum p span {
	margin-right: 15px;
}
.datum p span.ausbildung {
	margin-bottom: 0;
}
#ajaxContent .ausbildung {
	margin-bottom: -10px;
}
.bewerbungStellenanzeige .logo p {
	margin-top: 10px;
}
.bewerbungStellenanzeige .details .arbeitsort {
	margin-bottom: 15px;
}
@media screen and (max-width: 799px) {
	.ausbildung, #ajaxContent .ausbildung {
		margin-bottom: 30px;
	}
	.datum p span.ausbildung {
		margin-top: 15px;
	}
}
.margin-top-30px {margin-top:30px;}
.margin-bottom-30px {margin-bottom:30px;}
.margin-top-15px {margin-top:15px;}
.margin-bottom-15px {margin-bottom:15px;}
main > .bereich-inhalt .columns .row .laufzeit {
	margin: 0;
}

/* Cookie-Hinweis */
.cc-window.cc-banner {
  margin:0 auto;
  max-width:1200px;  
  padding:5px;
} 
.cc-message {
 font-size:1rem;
}

.cc-message a {
	color:#fff;
}
.font-normal {
	font-style: normal;
}