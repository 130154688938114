
.bereich-stellenanzeigen .inhalt-plugin-stellenanzeigen .inhalt .row:last-child{
		box-shadow: none;	
		}
.bereich-stellenanzeigen {
		background: #e5e4ea;
		background: -moz-linear-gradient(top,  #e5e4ea 0%, #ffffff 100%);
		background: -webkit-linear-gradient(top,  #e5e4ea 0%,#ffffff 100%);
		background: linear-gradient(to bottom,  #e5e4ea 0%,#ffffff 100%);
		padding: $standardabstand 15px 0 15px;		
		background-size: 100% 150px;
		background-repeat: no-repeat;
}
.bereich-stellenanzeigen .wrapper > .row {
	margin: 0 -15px;
}
.bereich-stellenanzeigen .wrapper > .row > .columns {
	padding: 0 15px;
}
.bereich-stellenanzeigen .ueberschrift .inhalt {
	margin-bottom: 50px;
}
.bereich-stellenanzeigen .inhalt-plugin-stellenanzeigen .inhalt .row > .columns:nth-child(1) {
	margin-bottom: $standardabstand;
}
.bereich-stellenanzeigen .inhalt-plugin-stellenanzeigen .inhalt .row > .columns:nth-child(2) {
	padding: 0 10px;
}
.bereich-stellenanzeigen .inhalt-plugin-stellenanzeigen h3 {
	font-size: 23px;
}

.bereich-stellenanzeigen .inhalt-plugin-stellenanzeigen .inhalt .row:last-child {
	border-bottom: 0;
}
.ort {
	padding:5px 0 15px 25px;
	background: url(../../images/icons/ort.png) no-repeat 0 5px;
}
.topberufe {
	background:$white;
	border:1px solid $medium-gray;
	padding:20px;
	margin:0 0 20px 0;
}
.topberufe h3{		
	margin:0 0 $standardabstand 0;
	text-align: center;
}
.topberufe ul {
	list-style: none;
}
.topberufe ul li a {
	color:$black;
}
.topberufe ul li {
	margin:0 0 20px 0;
	position: relative;
}
.topberufe ul li:last-child {
	margin-bottom: 0;
}
.topberufe ul li a {
	display: block;
	padding: 0 0 0 40px;
	word-break: normal;	
	-webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;	
}
.topberufe ul li a img {
	position: absolute;
	left: 0;
	top: 0;
	/*display: inline-block;
	padding: 0 20px 0 0;*/
	
}

.startseiteAlleStellen {
  text-align:right;
}

/* Bewerbungen */

#zusatzregistrierung {
 
}


@media screen and (min-width: 320px) {

	.bereich-stellenanzeigen .inhalt-plugin-stellenanzeigen .button {
		width:$breite-100pz;
	}
	.stellenanzeigen_uebersicht #ajaxContent .start_item .button {
		width:$breite-100pz;
	}
}
@media screen and (max-width:599px){
	
	/*.bereich-stellenanzeigen .inhalt-plugin-stellenanzeigen img,
	.stellenanzeigen_uebersicht #ajaxContent img {
		max-width:26%;
	}*/
	.bereich-stellenanzeigen .inhalt-plugin-stellenanzeigen img {
		margin: 10px 0;
	}
	.start_item .datum {
		margin-top: -37px;
		text-align:right;
	}
	.filter-trigger .button.secondary {
		max-width:200px;
	}
}

@media screen and (min-width: 600px) and (max-width:799px){
.bereich-stellenanzeigen .inhalt-plugin-stellenanzeigen .inhalt .row > .columns:nth-child(2){
	width:50%;
	}
	
	.stellenanzeigen_uebersicht .ort{
	padding-bottom:0;
	}
	.filter-trigger .button.secondary {
		width:50%;
	}
}
@media screen and (max-width: 799px) {

    .startseiteAlleStellen {
		text-align:center;
		padding: 20px 0 !important;
	}
	.startseiteAlleStellen a {
		display: inline-block;
		vertical-align: middle;
		margin: 0 0 1rem 0;
		padding: 0.85em 1em;
		-webkit-appearance: none;
		border: 1px solid transparent;
		border-radius: 5px;
		transition: background-color 0.25s ease-out, color 0.25s ease-out;
		font-size: 1rem;
		line-height: 1;
		text-align: center;
		cursor: pointer;
		background-color: #B2C937;
		color: #313131;
    }

	.bereich-stellenanzeigen .inhalt-plugin-stellenanzeigen .inhalt .row > .columns:nth-child(2) {
		margin-bottom: $standardabstand;
	}
	main .filter_stellenanzeigen > #ajaxFilter{
		/*margin:0 0 0 15px;*/
	}
	.stellenanzeigen_uebersicht #ajaxContent .start_item .button,.bereich-stellenanzeigen .inhalt-plugin-stellenanzeigen .button{
		margin-top:-18px;
	}
}
@media screen and (min-width: 800px){

	.bereich-stellenanzeigen .inhalt-plugin-stellenanzeigen .inhalt .row,.stellenanzeigen_uebersicht #ajaxContent > .start_item {
		padding:30px 0;
		border-bottom:1px solid #b9b9b9;
	}
	.stellenanzeigen_uebersicht #ajaxContent > div:first-child{
		margin-top:$standardabstand;
	}
	.stellenanzeigen_uebersicht #ajaxContent{
		/*margin:0 10px 0 10px;*/
	}
	.bereich-stellenanzeigen .inhalt-plugin-stellenanzeigen .inhalt .row:last-child a{
		font-size:20px;
		}
}
@media screen and ( max-width:799px){
	main .bereich-stellenanzeigen .inhalt > div,
	.stellenanzeigen_uebersicht #ajaxContent > .start_item{
		padding:10px 0;
		border-bottom:1px solid #b9b9b9;
	}

	.stellenanzeigen_uebersicht #ajaxContent > .banner_item.start_item {
	 padding:20px 0;
	}
	
	main > .row{
		margin-left:15px;
		margin-right:15px;
	}
	main #filter{
		margin-bottom:15px;
	}
	.bereich-stellenanzeigen .inhalt-plugin-stellenanzeigen .inhalt .row:last-child a{
		font-size:20px;
	}
	.bereich-stellenanzeigen .inhalt-plugin-stellenanzeigen .inhalt .row > .columns:nth-child(1),
	.bereich-stellenanzeigen .inhalt-plugin-stellenanzeigen .inhalt .row > .columns:nth-child(2) {
		margin-bottom: 0;
		padding: 0;
	}
}

#filter-accordion .accordion-title {
	border: 0;
	color: $black;
	font-size: inherit;
}
#filter-accordion .accordion-title h2 {
	margin-bottom: 0;
}
#filter-accordion .accordion-title:hover,#filter-accordion .accordion-title:focus {
	background: transparent;
	text-decoration: none;
}
#filter-accordion .accordion-content {
	padding: 0;
	border: 0;
	background: transparent;
}
	
@media screen and (min-width: 800px){
	#filter-accordion .accordion-title:before {
		content: none;
	}
	#filter-accordion .accordion-content {
		display: block;
	}
	.filter_stellenanzeigen {
		position: static;
		transition: none;
		transform: unset;
	}
	.position-right.is-transition-push::after {
		content: none;
	}
	.stellenanzeigen_uebersicht .left a.button{
		margin-top:$standardabstand;
	}
	.stellenanzeigen_uebersicht .left .fname{
		display:none;
	}
}
@media screen and (max-width: 799px){
	.filter-trigger {
		margin: 10px 0;
	}
	#anzeigen #zeigFilterdiv{
		box-sizing:border-box;
		background: $white;
		border:1px solid #b9b9b9;
		margin-left:-15px;
		position:fixed;
		bottom:0;
		width:100%;
		
	}
	.filter-trigger .button.secondary {
		background:$white;
		color: $marine-blau;
		position:relative;
		margin:auto;
		display:block;
		font-size:20px;
		border:1px solid #b9b9b9;
		
	}
	.stellenanzeigen_uebersicht .left .info,#c119 .info{
		display:none;
	}
	.stellenanzeigen_uebersicht .left .fname, #c119 .fnamestart{
		font-weight:bold;
		font-size:18px;
	}
}

/*
	.stellenanzeigen_index > h1, .stellenanzeigen_index > h2,
	.news_index > h1, .news_index > h2{
		margin:0;
		margin-bottom:10px;
	}
	.news_index > h2{
		margin-bottom:50px;
	}
	.stellenanzeigen_index .button{
		margin:20px 0 0 0;
	}
	.stellenanzeige_detail_ort span.region{
		font-family:$font-bold-montserrat;
		font-weight:normal;
	}
	.stellenanzeigen_index_jobalarm, .stellenanzeigen_index_jobalarm:hover{
		float:left;
		color:$black;
		display:block;
		margin:$standardabstand 0 0 0;
	}
	.stellenanzeigen_index_jobalarm img{
		margin:0 20px 0 0;
	}
	.stellenanzeigen_index_alle{
		float:right;
		margin:$standardabstand 0 0 0;
	}
	.stellenanzeigen_index_text{
		padding:0 0 0 10px;
	}

	.left .start_item:last-child{
		border-bottom:none;
	}
*/	