.bereich-news {
	padding: 0 15px;
}
.bereich-news .inhalt-plugin-seitenteaser, .bereich-news .inhalt-plugin-nachrichten {
	margin-bottom: $standardabstand;
}
.bereich-news .row:nth-child(2) .columns:nth-child(1) .inhalt > .row > a {
	background: #fff url(../../images/icons/pfeil_news_index.gif) no-repeat 98% center;
	border-bottom: 1px solid $medium-gray;
	display: block;
	padding: 20px 40px 20px 0;
}
.bereich-news .row:nth-child(2) .columns:nth-child(1) .inhalt > .row > a:hover {
	text-decoration:underline;
	background-color: #e6e6e6;
}
.bereich-news .row:nth-child(2) .columns:nth-child(1) .inhalt > .row > a:after {
	content: ' ';
	display: table;
	clear: both;
}
.bereich-news .row:nth-child(2) .columns:nth-child(1) .inhalt .columns:nth-child(1) h3 {
	font-weight: 400;
}
.bereich-news .row:nth-child(2) .columns:nth-child(1) .inhalt .columns:nth-child(2) p {
	font-family: $font-regular-montserrat;
	font-weight:normal;
	color:$black;
}
.bereich-news .row:nth-child(2) .columns:nth-child(1) .inhalt > .row:last-child {
	margin-top: $standardabstand;
}

.bereich-news .row:nth-child(2) .columns:nth-child(2) .inhalt {
	background:$white;
	border:1px solid $medium-gray;	
	margin:0 0 20px 0;
	padding:20px;
}
.bereich-news .row:nth-child(2) .columns:nth-child(2) .inhalt ul {
	list-style: none;
}
.bereich-news .row:nth-child(2) .columns:nth-child(2) .inhalt ul li span {
	display: block;
	text-align: right;
}
.bereich-news .row:nth-child(2) .columns:nth-child(2) .inhalt .mehr-link {
	text-align: right;
	margin: 20px 0 0 0;
}
@media screen and (max-width:599px) {

}

/*.news_index_liste{
	width:99.9%;
	border-bottom: 1px solid $medium-gray;		
}
.news_index_links ul{
	list-style-type:none;
}
.news_index_links ul li span,
.stellenanzeigen_index span{
	color:$black;
	width:auto;
	font-family: $font-regular-montserrat;
	font-weight:normal;
	text-align: right;
	display: block;
}
.news_index_liste a div > h3{
	font-weight:normal;
}
.news_index_wrapper{
	padding:0 0 $standardabstand 0;
}
.news_index_wrapper a.button,
.news_index_links ul li a.button{
	margin:20px 10px 0 0;
	float:right;
}
.news_index_wrapper .left,
.stellenanzeigen_index .left{
	padding-right:20px;
}
.news_index_links ul li a.button{
	margin:20px 0 0 0;
}*/