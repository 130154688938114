.row .paketAuswahl {
	margin: $standardabstand -15px;
}
.row .paketAuswahl .columns {
	padding: 0 15px;
	margin-bottom: $standardabstand;
}

.paketAnzeige {
	padding: 5px;
}

.paketAnzeige h2, .paketAnzeige h3 {
	text-align: center;
}

.paketAnzeige h3 {
	font-size: 1rem;
}
.paketAnzeige .paketAnzeigeLaufzeit, .paketAnzeige .paketAnzeigeLayout span {
	font-size: 0.875rem;
}

.paketAnzeige .paketAnzeigeLayout {
	margin: 0 0 5px 0;
}

.paketAnzeige form {
	border: 1px solid $medium-gray;
	padding: 10px;
	text-align: center;
}

.paketAnzeigeTitel {
	background: $light-gray;
	padding: 10px 0;
	margin: 10px 0 10px 0;
}

.paketAnzeige button {
	margin: 10px 0 30px 0;
}

.paketAnzeigePreis {
	margin: 10px 0;
	font-size: 1.2em;
}

.bezahlmethoden {
	padding: 15px !important;
	border: 0 !important;
	margin: $standardabstand 0 0 0 !important;
}
.bezahlmethoden legend {
	display: block;
	font-family: $font-bold-montserrat;
	font-size: 20px;
	text-align: center;
	width: 100%;
}
.button-zeile {
	margin-top: $standardabstand; 
	margin-bottom: $standardabstand;
}
.bestelluebersicht {
	margin-bottom: 0;
}
.bestelluebersicht tbody tr:nth-child(2n) {
	background: none;
	border-color: inherit;
}
.bestelluebersicht tbody tr:hover {
	border-color: inherit;
}
.rahmen-schwarz {
	border-top:1px #000 solid;
}

#feldCoupon {
	width:400px;
	margin: 0 auto $standardabstand auto;
}

.neuerpreis {
	font-weight:bold;
	/* olor: $primary-color; */
	color: $black;
	text-decoration:underline;
	font-size: 1.1em;
}
 
 .lastschrift {
	border-bottom: 1px $black solid;
	padding: 0 10% 20px 10%;
	margin: $standardabstand 0;
	display: none;
}
.lastschrift > .row {
	margin-bottom: 10px;
}
.no-js .lastschrift {
	display: block;
}

.zahlungrahmen {
	border: 1px solid $medium-gray;
	padding: 10px;
}
table.bankverbindung {
	margin: 0;
	border-radius: 0;
	width: auto;
}
table.bankverbindung tbody {
	border: 0;
	background: transparent;
}
table.bankverbindung tbody,
table.bankverbindung tbody tr:nth-child(2n),
table.bankverbindung tbody tr:hover {
	border: 0;
	background: transparent;
}
table.bankverbindung tbody tr td {
	padding: 2px 10px 2px 0;
}