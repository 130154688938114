#nutzerwechsel {
	background: $white;
	border: 1px $primary-color solid;
	border-bottom: 0;
	padding: 5px;
	position: fixed;
	bottom: 0;
	right: 0;
	font-size: 14px;
}
#nutzerwechsel .button {
	font-size: 14px;
	padding: 3px;
}
@media screen and (min-width: 1024px)
{
	right: 15px;
}