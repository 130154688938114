#anmelden_index{
	background: url(../../inhalte/anmelden/bg.svg) no-repeat center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	padding: 15px 0 100px 0;
}	
#anmelden_index main {
	background:none;
}
#anmelden_index main > .row:nth-child(2) {
	background: rgba(255,255,255,0.7);
	color:$black;
	padding:$standardabstand 10px;
}
#anmelden_index{
	color: $white;
} 
#anmelden_index main label{
	color: $black;
}
#anmelden_index .password_verg {
	color: $marine-blau;
}
#anmelden_index .login {
	margin-right: 15px;
}
#anmelden_index fieldset {
	border: 0;
}
#anmelden_index .center{
	margin:0 auto;
}
#anmelden_index footer {
	bottom: 0;
	left: 0;
	position: fixed;
	width: 100%;
}
#anmelden_index footer .copyright {
	/*background: none;*/
}
@media (min-width: 320px) {

	body#anmelden_index main{
		margin: 0 $standardabstand;
	}
}
@media (min-width: 1280px) {
	body#anmelden_index main{
		margin: 0 20%;
	}	
}
@media screen and (max-width: 475px){
	main:nth-child(2) > div .registrieren >  .registrierungLink > a:first-child{
	margin-bottom:15px;
	}
}